import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("/api/data-list/categories/", {item: item})
        .then((response) => {
          commit('ADD_CATEGORY', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getServices({ commit }, id) {
    return new Promise((resolve, reject) => {
      let endpoint = id != null ? "/" + id : "";
      axios.get(`digital-payment/services${endpoint}`)
      .then((response) => {
        commit('SET_SERVICES', response.data.data)
        return resolve(response.data.data)
      })
      .catch(error => {reject(error)})
    })
  },
  getSinglePaymentServices({ commit }, id) {
    return new Promise((resolve, reject) => {
      let endpoint = id != null ? "/" + id : "";
      axios.get(`digital-payment/services/mode/1${endpoint}`)
      .then((response) => {
        commit('SET_SERVICES', response.data.data)
        return resolve(response.data.data)
      })
      .catch(error => {reject(error)})
    })
  },
  getRecurringPaymentServices({ commit }, id) {
    return new Promise((resolve, reject) => {
      let endpoint = id != null ? "/" + id : "";
      axios.get(`digital-payment/services/mode/2${endpoint}`)
      .then((response) => {
        commit('SET_SERVICES', response.data.data)
        return resolve(response.data.data)
      })
      .catch(error => {reject(error)})
    })
  },
  getActiveServices({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/digital-payment/services/active`)
      .then((response) => {
        commit('SET_SERVICES', response.data.data)
        return resolve(response.data.data)
      })
      .catch(error => {reject(error)})
    })
  },
  addService({dispatch}, item) {
    return new Promise((resolve, reject) => {
      axios.put("digital-payment/service", { name: item.name_service, status: item.status_service })
        .then((response) => {
          if (response.status) {
            dispatch('getServices')
            .then(() => {
              resolve(response)
            })
          } else {
            reject(response.msg)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  updateService({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`/digital-payment/service/${item.id_service}`, {name: item.name_service, status: item.status_service })
        .then((response) => {
          // commit('UPDATE_SERVICE', response.data.data)
          commit('UPDATE_SERVICE', item)
          resolve(response)
        })
        .catch(error => reject(error) )
    })
  },
  getServicesByInstitution({ commit }, payload) {
    // Params:
    //    id_main_institution INT
    return new Promise((resolve, reject) => {
      axios.get(`/digital-payment/establishment/services/1/${payload}`)
        .then(response => {
          if(!response.data.status)
            reject(response.data.msg)

          commit('SET_SCHOOL_SERVICES', response.data.data)
          resolve(response.data.data)
        })
    })
  },
  updateInstitutionServices({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/digital-payment/service/assign', payload)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_INSTITUTION_SERVICE_ITEMS', payload)
          return resolve(response)
        })
        .catch(err => console.log(err))
    })
  },
  assignInstitutionService({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/digital-payment/service/assign`, payload)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('INSTITUTION_SERVICE_ASSIGN', payload)
          return resolve(response.data)
        })
    })
  },
  unassignInstitutionService({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/digital-payment/service/assign`, { data: payload })
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('INSTITUTION_SERVICE_UNASSIGN', payload)
          return resolve(response.data)
        })
    })
  },
  // Get schools asociated to a service
  getInstitutionsByService({ commit, dispatch, rootGetters }, serviceId) {
    return new Promise((resolve, reject) => {
      axios.get(`/digital-payment/service/establishment/${serviceId}`)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          // if(rootGetters['dataListInstitutions/getSchools'] === undefined || (rootGetters['dataListInstitutions/getSchools'] && rootGetters['dataListInstitutions/getSchools'].length == 0)) {
          if(rootGetters['dataListInstitutions/getSchools'] === undefined || rootGetters['dataListInstitutions/getSchools'].length == 0) {
              return new Promise((resolve) => {
                return dispatch('dataListInstitutions/getSchools', null, {root: true})
                .then(() => {
                  return resolve(response)
                })
                .catch(error => reject(error))
              })
          } else {
            return response
          }
        })
        .then(response => {
          const schools = rootGetters['dataListInstitutions/getSchools']
          // id_applicable_entity = 1: Client, 2: Establishment
          // id_service_payment_mode = 1: Single Payment, 2: Recurrent Payment
          const schoolsAsociated = response.data.data.filter(s => s.id_service_payment_mode == 1).map(s => {
            const school = schools.find(sc => sc.id == s.id_establishment)
            s.id = school.id
            s.name = school.name
            s.name_owner = school.name_owner
            s.tiptap_commission = school.tiptap_comission
            s.percent_guardian = s.percent_guardian || 0

            return s
          })

          commit('SET_INSTITUTIONS_SERVICE', schoolsAsociated)
          resolve(schoolsAsociated)
        })
        .catch(error => console.log(error))
    })
  },
  addSchoolAcademicPeriod({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/school/school_academic_periods/add`, payload)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('SET_SCHOOL_ACADEMIC_PERIOD', response.data.data)
          return resolve(response.data)
        })
        .catch(err => console.log(err))
    })
  },
  editSchoolAcademicPeriod({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/school/school_academic_periods/mod`, payload)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('SET_SCHOOL_ACADEMIC_PERIOD', response.data.data)
          return resolve(response.data)
        })
        .catch(err => console.log(err))
    })
  },
  getSchoolAcademicPeriodBySchool({ commit }, idSchool) {
    return new Promise((resolve, reject) => {
      axios.get(`/school/school_academic_periods/school/${idSchool}`)
        .then(response => {
          if(!response.data.status)
            reject(response.data.msg)

          commit('SET_SCHOOL_ACADEMIC_PERIOD', response.data.data)
          return resolve(response.data)
        })
        .catch(err => console.log(err))
    })
  },
  activeService({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/digital-payment/service/type`, payload)
        .then(response => {
          if (!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_SERVICE_STATUS', payload)
          return resolve(response)
        })
        .catch(err => console.log(err))
    })
  },
  deactiveService({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/digital-payment/service/type`, { data: payload })
        .then(response => {
          if (!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_SERVICE_STATUS', payload)
          return resolve(response)
        })
        .catch(err => console.log(err))
    })
  },
}
