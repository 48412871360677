export default {
    getService: state => (id) => state.services.find((s) => s.id_service == id),
    // getServices: state => state.services
    getSchoolServices: state => {
      return state.school_services.map(sc => {
        sc.service_status = state.services.find(s => s.id_service == sc.id_service).status_service || 0
        return sc
      });
    },
    getSchoolAcademicPeriod: state => state.school_academic_periods
  }