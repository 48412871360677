<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>Asociar Servicio</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Servicio -->
        <vs-input
          disabled="disabled"
          label="Nombre del Servicio"
          v-model="dataName"
          class="mt-5 w-full"
          name="item-service-name" />
        <span class="text-danger text-sm" v-show="errors.has('item-service-name')">{{ errors.first('item-service-name') }}</span>
        <vs-alert v-show="!dataServiceStatus" style="height: initial" color="danger" class="mt-2" icon-pack="feather" icon="icon-info">
          El servicio se encuentra <strong>inactivo</strong>. <br>
          Si bien puedes editar los datos, este servicio no se mostrará disponible para pagos en la aplicación Tiptap.
        </vs-alert>
        <!-- Asociado -->
        <div class="mt-5">
            <label class="vs-input--label w-full">Estado de asociación al servicio</label>
            <vs-switch
                v-model="dataStatus"
                class="w-1/4 my-2"
                >
                <template #off>
                    No Asociado
                </template>
                <template #on>
                    Asociado
                </template>
            </vs-switch>
            <p><span>Para asociarse al servicio, deslice la opción a <strong>asociado</strong>. Al hacerlo, los acudientes podrán abonar este servicio desde la aplicación móvil <strong>Tiptap</strong>.</span></p>
        </div>



        <!-- Monto Fijo  -->
         <!-- :disabled="tr.subscribed == 0 && !update.includes(tr.id_main_institution)"  -->
        <vs-input
            label="Ingrese monto fijo (opcional)"
            name="monto_fijo"
            type="number"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            v-model="dataAmount"
            class="mt-5 w-full inputx"
            v-validate="{ regex: /\d+(\.\d+)?$/, max_value: 9999, decimal: true, noBetweenZeroAndOne: true }"
            :disabled="dataStatus == 0"
            @input="checkNegative"
        />
        <span class="text-danger text-sm" v-show="errors.has('monto_fijo')">{{ errors.first('monto_fijo') }}</span>
        <p v-if="!dataStatus"><span>Para establecer un valor fijo, debe estar asociado al servicio.</span></p>
        <p v-else><span>Al establecer un valor fijo, los acudientes verán este monto a abonar en la aplicación móvil <strong>Tiptap</strong> y no podrán modificarlo. Si no desea establecer un valor fijo, puede dejar este campo vacío o con valor 0 (cero).</span></p>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { Validator } from 'vee-validate';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id_service, id_establishment, status_digital_payment, name_service, amount, id_service_type, items, service_status } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id_service
        this.dataIdMainInstitution = id_establishment
        this.dataName = name_service
        this.dataAmount = amount
        this.dataStatus = status_digital_payment ? true : false
        this.dataIdServiceType = id_service_type
        this.dataItems = items
        this.dataServiceStatus = service_status
        this.dataStatusDigitalPayment = status_digital_payment
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  data() {
    return {
      dataId: null,
      dataIdMainInstitution: null,
      dataName: null,
      dataAmount: 0,
      dataStatus: false,
      dataIdServiceType: null,
      dataItems: null,
      dataServiceStatus: null,

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid() {
      // return !this.errors.any() && (this.dataStatus && this.dataIdMainInstitution === null || !this.dataStatus && this.dataIdMainInstitution !== null || (this.dataStatus && this.dataAmount))
      return !this.errors.any() && (this.dataStatusDigitalPayment != this.dataStatus || this.data.amount != this.dataAmount)
    },
  },
  methods: {
    initValues() {
        this.dataId = null
        this.dataIdMainInstitution = null
        this.dataName = null
        this.dataAmount = 0
        this.dataStatus = null
        this.dataIdServiceType = null
        this.dataItems = null
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          let obj = {
            id_establishment: this.dataIdMainInstitution || this.$store.state.AppActiveUser.institution.id,
            id_establishment_type: 1,
            id_service_types: this.dataIdServiceType,
            status_digital_payment: this.dataStatusDigitalPayment,
            amount: this.dataAmount ? parseFloat(this.dataAmount) : this.dataAmount
          }
          if(obj.amount === null) { obj.amount = 0 }

          this.dispatchAction(this.dataStatus ? 'assignInstitutionService' : 'unassignInstitutionService', obj)
        }
      })
    },
    dispatchAction(action, payload) {
      this.$store.dispatch(`servicesModule/${action}`, payload)
        .then(response => {
          if (response.status)
            this.$emit('showNotify', 'success', 'Registro Actualizado!', 'Se han guardado los cambios.')
            this.$emit('closeSidebar')
            this.initValues()
            this.$vs.loading.close()
        })
        .catch(err => {
          this.$emit('showNotify', 'danger', 'Error', err)
          this.$vs.loading.close()
        })
    },
    checkNegative() {
      if (this.dataAmount < 0)
        this.dataAmount = 0
    }
  },
  components: {
    VuePerfectScrollbar,
  },
  created() {
    Validator.extend('noBetweenZeroAndOne', {
      getMessage: field => 'El campo ' + field + ' debe ser al menos 1.00',
      validate: value => !(value > 0 && value < 1)
    });
    new Validator({ noBetweenZeroAndOneField: 'noBetweenZeroAndOne' });
  }

}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
