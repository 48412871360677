export default {
    ADD_SERVICE(state, item) {
      state.services.unshift(item)
    },
    SET_SERVICES(state, payload) {
      state.services = payload
    },
    UPDATE_SERVICE(state, payload) {
        const idx = state.services.findIndex((s) => s.id_service == payload.id_service)
        Object.assign(state.services[idx], payload)
    },
    UPDATE_SERVICE_STATUS(state, payload) {
      const idx = state.services.findIndex((s) => s.id_service == payload.id_service)
      state.services[idx].status_service = payload.status_service
    },
    REMOVE_ITEM(state, itemId) {
        const idx = state.services.findIndex((p) => p.id == itemId)
        state.services.splice(idx, 1)
    },
    SET_INSTITUTIONS_SERVICE(state, payload) {
      state.institutions_service = payload
    },
    SET_SCHOOL_SERVICES(state, payload) {
      state.school_services = payload.filter(p => p.id_service_payment_mode == 1)
    },
    UPDATE_SCHOOL_SERVICE(state, payload) {
      const idx = state.school_services.findIndex(s => s.id == payload.id_service)
      Object.assign(state.school_services[idx], payload)
    },
    UPDATE_INSTITUTION_SERVICE_ITEMS(state, payload) {
      const idx = state.institutions_service.findIndex(s => s.id_service_types == payload.id_service_types)
      state.institutions_service[idx].items[0].percent = parseFloat(payload.data[0].value)
      state.institutions_service[idx].items[1].percent = parseFloat(payload.data[1].value)
    },
    // ADD_SCHOOL_ACADEMIC_PERIOD(state, payload) {
    //   state.school_academic_periods.unshift(payload)
    // },
    SET_SCHOOL_ACADEMIC_PERIOD(state, payload) {
      state.school_academic_periods = payload
    },
    INSTITUTION_SERVICE_UNASSIGN(state, payload) {
      //replaced institutions_service for school_services
      const idx = state.school_services.findIndex(s => s.id_service_type == payload.id_service_types)
      state.school_services[idx].status_digital_payment = 0
      state.school_services[idx].amount = payload.amount
    },
    INSTITUTION_SERVICE_ASSIGN(state, payload) {
      //replaced institutions_service for school_services
      const idx = state.school_services.findIndex(s => s.id_service_type == payload.id_service_types)
      state.school_services[idx].status_digital_payment = 1
      state.school_services[idx].amount = payload.amount
    }
  }
  